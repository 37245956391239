import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { Scrollbar, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import {
  LayoutSl,
  SEO2,
  CBreadCrumb,
  AssetImage,
  LWrap2,
  RecruitNews,
  Vertical,
  CImgCard,
  WordPic,
  WordFull,
  ContainerCrumbs,
  InterviewPic,
  WordPicWords,
  CInterviewJumbotron,
  CQuoteBox,
  CInterviewMedia,
  CSectTitle,
  CSchedule,
  CStars,
  CLabelCard,
  CBtnList,
} from "../../../../components/_index";
import "../../../../assets/_sass/page/recruit/common.scss";
import { title } from "process";
import "../../../../assets/_sass/page/recruit/interview.scss";
import { useMobile } from "../../../../utils/use-series";

const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: "大西菜奈",
    content: "Nana Ohnishi",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
    exClass: "container_left_No1",
    exClassWord: "container_left_sp  no-copy",
  };

  const verticalDataTime = {
    title: " １日のスケジュール",
    content: "",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
  };
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "社員インタビュー",
      src: "/recruit/interview/",
    },
    {
      title: "大西菜奈",
      src: "/recruit/interview/article04/",
      exClass:"no-copy",
    },
  ];
  const interviewFullData1 = {
    title: "入社を決めた理由をお聞かせください",
    paragraph: [
      {
        title:
          "高校生の頃から、人をおもてなしする接客業に就きたいと、漠然と考えていました。そのような中、学生時代に友人と旅行でホテルを利用した際のフロントスタッフの接客がとても丁寧で心地よく、心を動かされたんです。そのときから「おもてなしの最高峰と呼ばれるホテル業界で働きたい」と、ホテル業界に絞って就職を考えるようになりました。",
      },
      {
        title:
          "「私がホテルを利用した際に感じた、お客さまに喜びや満足していただけるサービスはどのようなものなのか。サービス内容やスタッフの連携、見えないところではどのような工夫や努力がなされているのか」",
      },
      {
        title:
          "私自身の目でそれを確かめて、実現したいという強い気持ちを持って就職活動する中で、『その街ならではのおもてなしを提供する』というコンセプトを持つ当ホテルに出会いました。「この街でナンバーワン、お客さまにとってオンリーワンのホテルでありたいという想いを『THE』という言葉に込めている」という話を聞き、ここでなら私の想いが実現できるのではないかと感じて、入社を決めました。",
      },
    ],
  };
  const interviewFullData2 = {
    title: "職場の雰囲気や人間関係はいかがでしょうか",
    paragraph: [
      {
        title:
          "人間関係や雰囲気はとても良好で、ほかの会社で働くのを思い描いたことすら一度もありません。一人ひとりが思いやりの心を持った、明るく楽しい職場です。",
      },
      {
        title:
          "就職活動時、会社説明会に参加した際、現場のスタッフと人事部のスタッフがワイワイとお話しされていたのを見て、部署関係なく楽しく働ける職場環境なんだなと感じていました。実際に入社してみて、自分が感じていたイメージとまったく同じだったので、よい意味で驚きましたし安心しました。",
      },
      {
        title:
          "毎日一緒に過ごしている同僚に対しては、アイコンタクトで意思の伝達ができるくらいのチームワークが構築できています。上司や先輩とは、仕事の話だけではなくプライベートも気兼ねなく相談できるほど、関係性は良好です。疑問や不満などもため込むことなく話せて、満足度の高いサポートやフィードバックがいただける、非常にありがたい環境だなと感謝しています。",
      },
    ],
  };

  const interviewFullData3 = {
    title: "ホテルで働く魅力や、当社を目指す方々メッセージをお聞かせください",
    paragraph: [
      {
        title:
          "何といっても「ホテルでしか味わえない優雅なサービスや空間」を提供できることです。そしてお客さまの大切なひとときを演出し、喜びを共に感じられる点も大きな魅力でしょう。他には無い、私たちの業界ならではの特権と言えるかもしれません。",
      },
      {
        title:
          "一方で就職活動では「華やかに見える世界だが、裏側は泥臭い」と、どのホテルの説明会でも耳にしました。言葉通り、特に繁忙期になると気合いと根性が不可欠です。優れたサービスを提供するために、忙しさに負けない忍耐力も必要でしょう。私も調理部門のスタッフと話す際は、調理器具の音に負けないよう、お腹の底から声を出しています。",
      },
      {
        title:
          "「ポム・ダダン」ではクリスマスシーズンやアフターヌーンティーの時間帯が特にあわただしくなります。次々と注文が入る中、お客さまを待たせないよう迅速に対応しつつ、提供するサービスの質を保たなければなりません。料理の色や香り、食器の美しさなどお客さまの期待に見合った体験を提供できるよう細心の注意を払うのは大変なことです。",
      },
    ],
  };

  const interviewFullData4 = {
    title:
      "ホテルで働く魅力や、当社を目指す方々へのメッセージをお聞かせください",
    paragraph: [
      {
        title:
          "毎日数多くのお客さまと関わり、表情や声のトーンからお客さまの要望をくみ取って、それぞれに合ったサービスを提供できる。それがホテル業界で働く魅力だと私は思っています。",
      },
    ],
  };
  const picData1 = {
    img: {
      src: "/assets/images/recruit/interview/article04/interview_article04_02.png",
      alt: "",
    },
    title: "現在の業務内容を教えてください",
    paragraph: [
      {
        title:
          "フロントカウンターにて、チェックイン・チェックアウト業務、お客さまからのリクエスト対応、荷物の発送手続きなど多岐にわたる業務を担当しております。インチャージ（責任者）として勤務する際は、周囲の状況を常に把握し、現場のコントロールと他のスタッフへ業務指示も合わせて実施しています。",
      },
      {
        title:
          "フロント業務を行う上で大切なことは、スタッフ同士の引継ぎです。フロント業務は24時間体制のため、日勤・夜勤とシフトを組んで勤務しています。シフトの入れ替わり時に引継ぎ事項が上手く伝達できていないと、お客さまに適切な対応ができず、不信感や不安を与えてしまいかねません。スムーズな引継ぎの実施は、ホテリエとして最も重要な業務だと私は考えています。",
      },
      {
        title:
          "私自身語学があまり得意ではないので、インバウンドのお客さまからイレギュラーな対応を求められると、忙しいときには気持ちが焦ってしまうものです。そのような際も、お客さまの気持ちに寄り添ったサービスを提供するため、笑顔を絶やさず言動行動に細心の注意を払って業務に取り組んでいます。",
      },
    ],
    subcontent: "",
    // position: true,.
    positionSp: true,
  };

  const picData3 = {
    img: {
      src: "/assets/images/recruit/interview/article04/interview_article04_03.png",
      alt: "",
    },
    title: "コミュニケーションに関して印象的な出来事はありますか",
    paragraph: [
      {
        title:
          "先日、お連れさまの誕生日を祝うため、サプライズで先にチェックインして客室を飾りつけしたいといった女性のお客さまが来館されました。段取りどおり先に女性が来館するところまではよかったのですが、チェックイン途中でお連れさまが来館し、カウンターにて鉢合わせしてしまうハプニングが起きてしまったんです。",
      },
      {
        title:
          "そこで焦ることなく同僚とアイコンタクトをかわし、私からお連れさまへ「お部屋のご準備が整っておりません。お部屋のご準備ができ次第お声かけさせていただきますので、ロビーでお待ちいただけますか」とご案内。その間に、同僚は女性をお手洗いまでご案内してカードキーを渡し、そのままお部屋に上がり飾りつけを一緒にお手伝い。飾りつけが終わったタイミングでお連れさまにお声かけし、無事サプライズを実施していただけました。",
      },
      {
        title:
          "同僚でなければアイコンタクトで機転を利かすのは難しかったでしょうし、普段からのチームワークが大切だなと感じた出来事でしたね。",
      },
    ],
    subcontent: "",
    position: true,
  };
  const picData4 = {
    img: {
      src: "/assets/images/recruit/interview/article04/interview_article04_04.png",
      alt: "",
    },
    title: "仕事のやりがいや達成感はどのようなところに感じていますか",
    paragraph: [
      {
        title:
          "当ホテルは、中国やアメリカをはじめとする各国からのお客さまに数多くご利用いただいています。入社当初、私は外国語がまったく理解できない状態でした。それではダメだとチェックインやチェックアウトのフレーズから始め、毎日インプットアウトプットを繰り返して英語でコミュニケーションが取れるようになったことは、仕事への自信につながっています。チェックインを対応したお客さまより「笑顔と対応がよかった」とチェックアウト時に国のお菓子とお手紙をいただけたときには、自分の努力は間違っていなかったと感じましたね。",
      },
      {
        title:
          "また、今年度の新入社員教育の担当者に選ばれたことも、仕事へのモチベーションが大きく上がった出来事です。新入社員の研修を行うにあたり、研修のスケジュール行程や資料作成といった準備を一から1人で行い、無事4日間の新入社員の研修を何事もなく終えられたときには、達成感でいっぱいでした。現場に出てからも問題なく業務をこなしている姿を見ると、泣きそうになるほど嬉しかったです。",
      },
    ],
    subcontent: "",
    //position: true,
    positionSp: true,
  };
  const picData5 = {
    img: {
      src: "/assets/images/recruit/interview/article04/interview_article04_05.png",
      alt: "",
    },
    title: "",
    paragraph: [
      {
        title:
          "先ほどもお伝えしたとおり、当社は一人ひとりが思いやりの心を持った、明るく楽しい職場です。自分の職場を家族や友人に紹介するならば「訪問して働いているところを見てもらえれば良さがわかる」と言いきれるくらいに、この職場に魅力を感じています。",
      },
      {
        title:
          "インバウンドのお客さまが増えていることで、英語ができないことを不安に思われる方もいらっしゃるかもしれません。そのようなときでも、ジェスチャーや紙を使うなど、どうにかして伝えたい気持ちを持って接すれば、お客さまには伝わるものです。誰かを満足させたいという気持ちを強く持っている方には、当社の仕事は非常に向いていると考えます。知識は入社後でもつけられますので、安心して入社してきてくださいね。",
      },
    ],
    subcontent: "",
    position: true,
  };

  const carouselData = [
    {
      img: {
        src: "/assets/images/recruit/interview/interview.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 東京汐留 フロントチーム",
      content: (
        <>
          「人と関わる」を極めるためアニメ制作の道からホテルマンに軌道修正。
          <br />
          入社10年目を前に改めて「意思疎通や連携を大事にする会社」と実感
        </>
      ),

      end_title: "2015年 新卒入社",
      jumpUrl: "/recruit/interview/article01/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview2.png",
        alt: "",
      },
      title: "丸ノ内ホテル 料飲　(レストラン ポム・ダダン) ",
      content: (
        <>
          お客さまの大切な時間を共有できるのがレストラン部門の醍醐味。
          <br />
          価値ある取り組みを紹介する「真心感動ボード」も仕事への意欲に
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article02/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview3.png",
        alt: "",
      },
      title: "ザ ロイヤルパーク キャンバス 大阪北浜 フロントチーム",
      content: (
        <>
          「さまざまな接客を経験したい」と、これまで経験のないフロント業務ができる当社へ。ライフスタイルホテルの最高峰を目指し、若いスタッフの意見を採り入れてイベントを実施する
        </>
      ),

      end_title: "2019年 キャリア入社",
      jumpUrl: "/recruit/interview/article03/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview4.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 大阪御堂筋 フロントチーム",
      content: (
        <>
          学生時代に宿泊したホテルのサービスに感動し、ホテル業界で働きたいと当社へ就職。お客さまやスタッフとのコミュニケーションを高め、おもてなしの最高峰を極める
        </>
      ),

      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article04/",
    },

    {
      img: {
        src: "/assets/images/recruit/interview/interview5.png",
        alt: "",
      },
      title:
        "ロイヤルパークホテル（東京・日本橋）調理部 洋食調理二課 バンケットコールド調理",
      content: (
        <>
          作る料理に一切の妥協をしない。
          <br />
          コンクールにも挑戦し、自分の武器を増やしながら成長を重ねる毎日
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article05/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview6.png",
        alt: "",
      },
      title: "仙台ロイヤルパークホテル 婚礼",
      content: (
        <>
          「不採用でもあきらめない」
          <br />
          子どもの頃の憧れを形に掴んだキャリア
        </>
      ),

      end_title: "2020年 キャリア入社",
      jumpUrl: "/recruit/interview/article06/",
    },
  ];
  return (
    <LayoutSl isKv={false}>
      <SEO2
        title={frontmatter?.title}
        description={frontmatter?.description}
        isTop={false}
      />
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="new_main">
            <ContainerCrumbs data={crumbsData} />
            <div className="container_main container_main_interview ">
              <Vertical data={verticalData} />
              <div className="container_right interview-container-right">
                <AssetImage
                  src="/assets/images/recruit/interview/article04/interview_article04.png"
                  alt=""
                  loading="lazy"
                  wrapClassName="article-pc"
                />

                {isSp && (
                  <AssetImage
                    src="/assets/images/recruit/interview/article04/interview-article04-media.png"
                    alt=""
                    loading="lazy"
                    wrapClassName="article-media"
                  />
                )}
                <div className="interview_title">
                  <p className="m_title_message m_title_message-media">
                    学生時代に宿泊したホテルのサービスに感動し、
                    <br className="u_pc" />
                    ホテル業界で働きたいと当社へ就職。
                    <br />
                    お客さまやスタッフとのコミュニケーションを高め、
                    <br className="u_pc" />
                    おもてなしの最高峰を極める
                  </p>
                </div>
                <div className="interview_subtitle">
                  <p className="m_subtitle_message m_title_subtitle-media">
                    2020年 新卒入社
                    <br />ザ ロイヤルパークホテル アイコニック 大阪御堂筋
                    フロントチーム
                  </p>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="person-article-div">
            <div className="new_main">
              <WordFull
                data={interviewFullData1}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData1}
                exClass="container_message_picture_same container_main_message_bisect2"
                exRightClass="article04-p1-p"
              />
              <WordFull
                data={interviewFullData2}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData3}
                exRightClass="right-pic-media-left"
                exClass="container_main_message_bisect2"
              />
              {/* <WordFull data={interviewFullData3} /> */}
              <WordPic
                data={picData4}
                exClass="reverse-container_main_message_bisect container_main_message_bisect2"
                exRightClass="small-right-pic bisect_right2"
              />

              <WordFull
                data={interviewFullData4}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData5}
                exClass="container_message_picture_same container_main_message_bisect2"
                exRightClass="article04-p5-p"
              />
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04  u_grey recruit-font">
        <LWrap2>
          <div className="person-article-div">
            <div className="new_main u_grey">
              <div className="container_main container_main_time_a container_main_time-media">
                <Vertical data={verticalDataTime} />
                <div className="interview_a1_mythBox article01-mythBox">
                  <div className="interview_a1_timebox-left interview_a1_timebox-left-media">
                    <p className="interview_a1_timebox-title">早番</p>
                    <div className="interview_a1_timeline-item">
                      <i className="interview_a1_timeline-axis">
                        <AssetImage
                          src="/assets/images/recruit/interview/time.svg"
                          alt=""
                          loading="lazy"
                        />
                      </i>
                      <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                        <div className="interview_a1_timeline-detail">
                          09:00
                        </div>
                        <div className="interview_a1_timeline-title">
                          出勤、引継ぎを受ける
                          <br />
                          チェックアウト業務
                        </div>
                      </div>
                    </div>
                    <div className="interview_a1_timeline-item">
                      <i className="interview_a1_timeline-axis">
                        <AssetImage
                          src="/assets/images/recruit/interview/time02.svg"
                          alt=""
                          loading="lazy"
                        />
                      </i>
                      <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                        <div className="interview_a1_timeline-detail">
                          11:00
                        </div>
                        <div className="interview_a1_timeline-title">
                          ゲスト対応、電話対応や
                          <br className="u_pc" />
                          日々のJOBチェック
                          <br />
                          午前中の会計確認
                        </div>
                      </div>
                    </div>
                    <div className="interview_a1_timeline-item">
                      <i className="interview_a1_timeline-axis">
                        <AssetImage
                          src="/assets/images/recruit/interview/time03.svg"
                          alt=""
                          loading="lazy"
                        />
                      </i>
                      <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                        <div className="interview_a1_timeline-detail">
                          12:00
                        </div>
                        <div className="interview_a1_timeline-title">休憩</div>
                      </div>
                    </div>
                    <div className="interview_a1_timeline-item">
                      <i className="interview_a1_timeline-axis">
                        <AssetImage
                          src="/assets/images/recruit/interview/time04.svg"
                          alt=""
                          loading="lazy"
                        />
                      </i>
                      <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                        <div className="interview_a1_timeline-detail">
                          13:00
                        </div>
                        <div className="interview_a1_timeline-title">
                          チェックイン業務
                        </div>
                      </div>
                    </div>
                    <div className="interview_a1_timeline-item">
                      <i className="interview_a1_timeline-axis">
                        <AssetImage
                          src="/assets/images/recruit/interview/time05.svg"
                          alt=""
                          loading="lazy"
                        />
                      </i>
                      <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                        <div className="interview_a1_timeline-detail">
                          17:30
                        </div>
                        <div className="interview_a1_timeline-title">
                          引継ぎ、 退勤
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="interview_a1_timebox-right interview_a1_timebox-right-media">
                    <p className="interview_a1_timebox-title">遅番</p>
                    <div className="interview_a1_timeline-item">
                      <i className="interview_a1_timeline-axis">
                        <AssetImage
                          src="/assets/images/recruit/interview/time06.svg"
                          alt=""
                          loading="lazy"
                        />
                      </i>
                      <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                        <div className="interview_a1_timeline-detail">
                          13:30
                        </div>
                        <div className="interview_a1_timeline-title">
                          出勤、引継ぎを受ける
                          <br />
                          チェックイン業務、コーディング作業
                        </div>
                      </div>
                    </div>
                    <div className="interview_a1_timeline-item">
                      <i className="interview_a1_timeline-axis">
                        <AssetImage
                          src="/assets/images/recruit/interview/time07.svg"
                          alt=""
                          loading="lazy"
                        />
                      </i>
                      <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                        <div className="interview_a1_timeline-detail">
                          18:00
                        </div>
                        <div className="interview_a1_timeline-title">休憩</div>
                      </div>
                    </div>
                    <div className="interview_a1_timeline-item">
                      <i className="interview_a1_timeline-axis">
                        <AssetImage
                          src="/assets/images/recruit/interview/time08.svg"
                          alt=""
                          loading="lazy"
                        />
                      </i>
                      <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                        <div className="interview_a1_timeline-detail">
                          19:00
                        </div>
                        <div className="interview_a1_timeline-title">
                          ゲスト対応、電話対応や
                          <br className="u_pc" />
                          日々のJOBチェック
                        </div>
                      </div>
                    </div>
                    <div className="interview_a1_timeline-item">
                      <i className="interview_a1_timeline-axis">
                        <AssetImage
                          src="/assets/images/recruit/interview/time09.svg"
                          alt=""
                          loading="lazy"
                        />
                      </i>
                      <div className="interview_a1_timeline-content text interview_a1_timeline-content-media">
                        <div className="interview_a1_timeline-detail">
                          22:00
                        </div>
                        <div className="interview_a1_timeline-title">退勤</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>

      <section className="l_sect04 recruit-font">
        <LWrap2>
          <div className="swiper swiper_buttons swiper_buttons_media">
            <div className="swiper-button-prev turnPrev"></div>&nbsp;
            <div className="swiper-button-next turnNext"></div>
          </div>
          <div className="new_main">
            <div className="container_main_carouse">
              {isSp ? (
                <>
                  <div className="carouse_right article-media article-swiper-media">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={1}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              ) : (
                <>
                  <div className="carouse_right article-pc">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={3.5}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="return_overview_class">
            {isSp ? (
              <>
                <Link to="/recruit/interview/" target="_blank">
                  <div className="return_overview_class_internal return-overview-media">
                    <div className="btn-list">一覧に戻る</div>
                    <div className="circle-icon">
                      <img
                        src="/assets/images/recruit/common/icon-new-right.png"
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </>
            ) : (
              <>
                <div className="return_overview_class_internal return-overview-media hover_active">
                  一覧に戻る
                  <a href="/recruit/interview/">
                    <img
                      src="/assets/images/recruit/goto.svg"
                      className="return_overview_images"
                    />
                  </a>
                </div>
              </>
            )}
          </div>
        </LWrap2>
      </section>
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
